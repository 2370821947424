import React, { useMemo } from "react";

import { Card, CardProps, styled } from "@mui/material";
import { Resizable } from "re-resizable";

type Area =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "topRight"
  | "bottomRight"
  | "bottomLeft"
  | "topLeft";

type ResizableCardProps = CardProps & {
  enabled?: boolean;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
  areas: Area[];
};

const StyledCard = styled(Card)({
  width: "100%",
  height: "100%",
  pointerEvents: "auto",
  position: "relative",
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
});

export function ResizableCard({
  enabled = true,
  width = 300,
  height = 300,
  minWidth = 300,
  minHeight = 300,
  maxWidth = 800,
  maxHeight = 800,
  areas = [],
  ...props
}: ResizableCardProps) {
  const enable = useMemo(() => {
    return Object.fromEntries(areas.map((area) => [area, enabled]));
  }, [areas, enabled]);

  return (
    <Resizable
      defaultSize={{ width, height }}
      minWidth={minWidth}
      minHeight={minHeight}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      enable={enable}
      style={{
        maxHeight: "100%",
        pointerEvents: enabled ? "auto" : "none",
      }}
    >
      {enabled && <StyledCard {...props} />}
    </Resizable>
  );
}
