import { Dataset } from "../Dataset";
import { TextIndexWorker } from "./TextIndex.worker";

import { wrap } from "comlink";
import { TextAttribute } from "../attributes/TextAttribute";
import { IndexableAttributeObject } from "../types";
import { CategoryAttribute } from "../attributes/CategoryAttribute";

const RemoteTextIndexWorker = wrap<typeof TextIndexWorker>(
  new Worker(new URL("./TextIndex.worker.ts", import.meta.url), {
    type: "module",
  })
);

export class TextIndex {
  dataset: Dataset;
  worker = new RemoteTextIndexWorker();

  constructor(dataset: Dataset) {
    this.dataset = dataset;
  }

  async build() {
    const worker = await this.worker;

    const objs: IndexableAttributeObject[] = [];
    for (const attribute of this.dataset.attributes) {
      if (attribute instanceof TextAttribute) {
        objs.push(attribute.toObject());
      }
      if (attribute instanceof CategoryAttribute) {
        objs.push(attribute.toObject());
      }
    }

    await worker.build(objs);
    return this;
  }

  async getTopWords(count = 10, selection?: number[]) {
    const worker = await this.worker;
    return worker.getTopWords(count, selection);
  }

  async search(query: string, previous?: number[]) {
    const worker = await this.worker;
    return worker.search(query, previous);
  }
}
