import _ from "lodash";

export type TypedArrayConstructor =
  | Int8ArrayConstructor
  | Int16ArrayConstructor
  | Int32ArrayConstructor
  | Uint8ArrayConstructor
  | Uint16ArrayConstructor
  | Uint32ArrayConstructor
  | Float32ArrayConstructor;

export type DType =
  | "int8"
  | "uint8"
  | "int16"
  | "uint16"
  | "int32"
  | "uint32"
  | "float32";

const TYPES: Record<DType, TypedArrayConstructor> = {
  int8: Int8Array,
  uint8: Uint8Array,
  int16: Int16Array,
  uint16: Uint16Array,
  int32: Int32Array,
  uint32: Uint32Array,
  float32: Float32Array,
};

function parseHeader(buffer: ArrayBuffer) {
  const data = new Uint8Array(buffer);
  const headerLength = data.indexOf(0);
  const headerData = new Uint8Array(buffer, 0, headerLength);
  const headerJson = new TextDecoder("utf-8").decode(headerData);

  if (!headerJson.startsWith("{")) {
    throw new Error("Invalid header");
  }

  const header = JSON.parse(headerJson);

  const items: HeaderItem[] = header.$items;
  delete header.$items;

  return { header, headerLength, items };
}

export function parse(buffer: ArrayBuffer): Record<string, any> {
  const { header, headerLength, items } = parseHeader(buffer);

  for (const item of items) {
    const ArrayConstructor = TYPES[item.dtype] || Uint8Array;
    const value = new ArrayConstructor(
      buffer,
      headerLength + item.offset,
      item.length / ArrayConstructor.BYTES_PER_ELEMENT
    );
    _.set(header, item.keys, value);
  }

  return header;
}

type Key = string | number;

interface HeaderItem {
  keys: Key[];
  offset: number;
  length: number;
  dtype: DType;
}

export function stringify(obj: Record<string, any>): Uint8Array {
  let offset = 8;
  const items: HeaderItem[] = [];
  const binaries: Uint8Array[] = [];
  // TODO: Make it TS friendly
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const iterate = (obj: Record<string, any> | TypedArray, keys: Key[]) => {
    if (_.isPlainObject(obj)) {
      for (const [key, value] of _.toPairs(obj)) {
        (obj as Record<string, any>)[key] = iterate(value, [...keys, key]);
      }
      return obj;
    }

    if (_.isArray(obj)) {
      // TODO: Make it TS friendly
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      for (const [key, value] of obj.entries()) {
        obj[key] = iterate(value, [...keys, key]);
      }
      return obj;
    }

    for (const [dtype, Constructor] of _.toPairs(TYPES) as [
      DType,
      TypedArrayConstructor
    ][]) {
      if (obj === undefined || obj === null) {
        // This value will be ignore in output
        console.debug(keys.map((k) => `${k} =>`).join(" ") + " is " + obj);
        return obj;
      }
      if (obj.constructor === Constructor) {
        const length = obj.byteLength;
        items.push({ keys, dtype, length, offset });
        binaries.push(
          new Uint8Array(obj.buffer, obj.byteOffset, obj.byteLength)
        );
        offset += length + (8 - (obj.byteLength % 8));
        return "";
      }
    }
    return obj;
  };

  const header = { ...iterate(obj, []), $items: items };
  const bodyLength = offset;

  const encodedHeader = new TextEncoder().encode(JSON.stringify(header));
  const headerPadding = 8 - (encodedHeader.length % 8);
  const headerLength = encodedHeader.length + headerPadding;

  const result = new Uint8Array(headerLength + bodyLength);

  result.set(encodedHeader);
  for (let i = encodedHeader.length; i < headerLength; i++) {
    result[i] = 32; // Fill with spaces
  }

  binaries.forEach((binary, index) => {
    result.set(binary, headerLength + items[index].offset);
  });

  return result;
}
