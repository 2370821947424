import React, { useRef, useCallback } from "react";
import { Stack, lighten, alpha, Typography } from "@mui/material";
import { useGesture } from "react-use-gesture";
import { BaseField } from "./BaseField";

export function SliderField({
  label = "Slider",
  value = 0.5,
  onChange,
  minValue = 0,
  maxValue = 1,
  renderValue = (value: number) => value.toFixed(2),
}: {
  label?: string;
  value?: number;
  onChange?: (value: number) => void;
  minValue?: number;
  maxValue?: number;
  renderValue?: (value: number) => React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const normalized = (value - minValue) / (maxValue - minValue);
  const percent = Math.round(normalized * 100).toFixed(2);
  const renderedValue = renderValue(value);

  const handleChange = useCallback(
    (value: number) => {
      const denormalized = value * (maxValue - minValue) + minValue;
      onChange?.(denormalized);
    },
    [onChange]
  );

  useGesture(
    {
      onDrag: ({ delta }) => {
        const width = ref.current?.clientWidth || 100;
        handleChange(Math.max(0, Math.min(1, normalized + delta[0] / width)));
      },
    },
    {
      domTarget: ref,
    }
  );

  return (
    <BaseField
      ref={ref}
      direction="row"
      justifyContent="space-between"
      padding={3}
      alignItems="center"
      sx={(theme) => ({
        position: "relative",
        cursor: "ew-resize",
        zIndex: 0,
        "&::after": {
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          width: `${percent}%`,
          height: "100%",
          backgroundColor: alpha(theme.palette.grey[300], 0.25),
          transition: theme.transitions.create(["background-color"]),
          zIndex: -1,
        },
      })}
    >
      <Typography sx={{ pointerEvents: "none" }} variant="body2">
        {label}
      </Typography>
      <Typography sx={{ pointerEvents: "none" }} variant="body2">
        {renderedValue}
      </Typography>
    </BaseField>
  );
}
