import React, { useCallback, useEffect, useRef } from "react";
import { Card, Stack, Input, Typography } from "@mui/material";
import { useKey } from "react-use";

import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export function SearchButton({
  value,
  onChange,
  onSubmit,
  onClear,
  openKey = "/",
  disabled = false,
}: {
  value: string;
  onChange?: (value: string) => void;
  onSubmit?: (value: string) => void;
  onClear?: () => void;
  inputWidth?: number;
  openKey?: string;
  disabled?: boolean;
}) {
  const ref = useRef<HTMLInputElement>(null);

  const [focused, setFocused] = React.useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [lastSubmitted, setLastSubmitted] = React.useState<string>("");

  useEffect(() => {
    if (value) {
      setExpanded(true);
    }
  }, [value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.value);
    },
    [onChange]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const currentValue = ref.current?.value || "";
        onSubmit?.(currentValue);
        setLastSubmitted(currentValue);
      }
      if (e.key === "Escape") {
        onClear?.();
      }
    },
    [onSubmit]
  );

  const handleClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (expanded) {
      onChange?.("");
      onSubmit?.("");
      setLastSubmitted("");
      setExpanded(false);
      return;
    }
    setExpanded(true);
    setTimeout(() => {
      ref.current?.focus();
    }, 100);
  }, [expanded, disabled]);

  useKey(openKey, () => {
    if (openKey) {
      setExpanded(true);
      setTimeout(() => {
        ref.current?.focus();
      }, 100);
    }
  });

  const submitted = lastSubmitted === value;

  return (
    <Card
      className={focused ? "focused" : ""}
      sx={(theme) => ({
        padding: 0,
        height: theme.spacing(11),
        minWidth: theme.spacing(11),
        pointerEvents: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        transition: theme.transitions.create([
          "width",
          "background-color",
          "color",
          "border-color",
        ]),
        overflow: "hidden",
        width: "auto",
        "&:hover": {
          cursor: "auto",
        },
        "&.focused": {
          borderColor: theme.palette.grey[300],
        },
        "&.focused:hover": {
          backgroundColor: theme.palette.background.paper,
        },
      })}
    >
      <Stack
        onClick={handleClick}
        sx={(theme) => ({
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: theme.spacing(2),
          width: theme.spacing(9),
          height: theme.spacing(11),
          flexShrink: 0,
          "&:hover": {
            cursor: "pointer",
          },
        })}
      >
        <SearchIcon sx={{ fontSize: 18 }} />
      </Stack>

      {/* Body */}
      <Stack
        sx={(theme) => ({
          flexGrow: 1,
          position: "relative",
          flexDirection: "row",
          justifyContent: "left",
          alignItems: "center",
          height: theme.spacing(11),
          transition: theme.transitions.create(["width", "opacity"]),
          opacity: expanded ? 1 : 0,
        })}
      >
        <Input
          inputRef={ref}
          value={value}
          onChange={handleChange}
          disableUnderline
          onClick={(e) => e.stopPropagation()}
          onKeyDown={handleKeyDown}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          inputMode="search"
          inputProps={{
            size: Math.max(20, value.length),
          }}
          sx={(theme) => ({
            margin: 0,
            padding: 0,
            width: expanded ? "auto" : 0,
            paddingLeft: theme.spacing(1),
            transition: theme.transitions.create(["width"]),
            color: submitted
              ? theme.palette.text.primary
              : theme.palette.text.secondary,
          })}
        />
      </Stack>
      {expanded && (
        <Stack
          sx={(theme) => ({
            justifyContent: "center",
            alignItems: "center",
            paddingRight: theme.spacing(2),
            width: theme.spacing(11),
            height: theme.spacing(11),
            flexShrink: 0,
          })}
        >
          {onClear && value.length > 0 && !disabled && (
            <CloseIcon
              onClick={onClear}
              sx={{
                fontSize: 14,
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            />
          )}
        </Stack>
      )}
    </Card>
  );
}
