import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useKey, useKeyPress } from "react-use";

import { Tooltip, Stack, Card, Typography, IconButton } from "@mui/material";

import { useSelectionStore } from "~stores/selection";
import { useDataStore } from "~stores/data";

import ArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { ScrollTextBox } from "../ui/ScrollTextBox";

import { Resizable } from "re-resizable";
import { TopWords } from "../TopWords";

import { DownloadSelectionButton } from "~components/buttons/DownloadSelectionButton";
import { ClearSelectionButton } from "../buttons/ClearSelectionButton";
import { ResizableCard } from "~components/ui/ResizableCard";

function Row({ label, value }: { label: string; value: string }) {
  return (
    <Typography
      sx={{ lineHeight: 1.25, overflow: "hidden", textOverflow: "ellipsis" }}
    >
      <b>{label}: </b>
      {value}
    </Typography>
  );
}

function Title({ id, position }: { id: number; position?: string }) {
  return (
    <Stack padding={2} overflow="hidden">
      <Stack direction="row" spacing={3}>
        <Typography color="primary">
          <b>ID: </b>
          <span>{id}</span>
        </Typography>
        <Typography color="secondary">{position}</Typography>
      </Stack>
    </Stack>
  );
}

function Document({
  id,
  values,
}: {
  id: number;
  values?: Map<string, string>;
}) {
  const rows = useMemo(() => {
    const rows: [string, string][] = [];
    if (values) {
      rows.push(...values.entries());
    }
    return rows.filter((row) => !["x", "y", "z"].includes(row[0]));
  }, [values]);

  return (
    <Stack spacing={2}>
      {rows.map(([key, value]) => (
        <Row key={key} label={key} value={value} />
      ))}
    </Stack>
  );
}

export function SelectionPanel() {
  const [currentId, setCurrentId] = useState(0);

  const { dataset } = useDataStore();
  const selection = useSelectionStore((s) => s.selectedIds);
  const highlighted = useSelectionStore((s) => s.highlightIds);

  const showingId = highlighted[0] ?? selection[currentId];
  const isHighlight = highlighted.length > 0;

  const [shiftPressed] = useKeyPress("Shift");
  const [currentValues, setCurrentValues] = useState<Map<string, string>>();
  const [projection, setProjection] = useState<string>();

  useEffect(() => {
    if (showingId !== undefined) {
      const values = dataset?.getDisplayValues(showingId);
      const projection = dataset?.projections?.getDisplayValue(showingId);
      setCurrentValues(values);
      setProjection(projection);
    }
  }, [showingId, selection, dataset]);

  const count = selection.length;

  const handleReset = useCallback(() => {
    setCurrentId(0);
  }, []);

  const handleMove = useCallback(
    (delta: number) => {
      setCurrentId((i) => Math.min(count - 1, Math.max(0, i + delta)));
    },
    [count]
  );

  const handlePrevious = useCallback(
    () => handleMove(shiftPressed ? -5 : -1),
    [handleMove, shiftPressed]
  );

  const handleNext = useCallback(
    () => handleMove(shiftPressed ? 5 : 1),
    [handleMove, shiftPressed]
  );

  useKey("ArrowLeft", handlePrevious, {}, [handlePrevious]);
  useKey("ArrowRight", handleNext, {}, [handlePrevious]);
  useEffect(handleReset, [count]);

  const handleWheel = useCallback(
    (event: React.WheelEvent) => {
      const sign = Math.sign(event.deltaY);
      handleMove(sign * Math.ceil(Math.abs(event.deltaY) / 10));
    },
    [handleMove]
  );

  const enabled = showingId !== undefined;

  if (!dataset) {
    return null;
  }

  return (
    <ResizableCard
      width={400}
      height={400}
      areas={["top", "right", "topRight"]}
      enabled={enabled}
    >
      {/* Header */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        padding={2}
        paddingBottom={0}
        spacing={2}
      >
        <Title id={showingId} position={projection} />
        <Stack direction="row" spacing={1}>
          <DownloadSelectionButton selection={selection} />
          <ClearSelectionButton />
        </Stack>
      </Stack>

      {/* Body */}
      <Stack marginTop={-2} flexGrow={1} flexShrink={1} overflow="hidden">
        <ScrollTextBox paddingLeft={4} paddingRight={4}>
          {enabled && <Document id={showingId} values={currentValues} />}
        </ScrollTextBox>
      </Stack>

      <Stack padding={3} direction="row" gap={1} flexWrap="nowrap">
        <TopWords />
      </Stack>

      {/* Footer */}
      <Stack
        padding={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Tooltip title="Previous">
          <IconButton onClick={handlePrevious}>
            <ArrowLeftIcon />
          </IconButton>
        </Tooltip>
        {!isHighlight && (
          <div onWheel={handleWheel} style={{ cursor: "ns-resize" }}>
            {currentId + 1} / {selection.length}
          </div>
        )}
        <Tooltip title="Next">
          <IconButton onClick={handleNext}>
            <ArrowRightIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </ResizableCard>
  );
}
