import * as THREE from "three";

export const computeCenteredBoundingBox = (box: THREE.Box3) => {
  return new THREE.Box3(
    new THREE.Vector3(
      -Math.max(Math.abs(box.min.x), Math.abs(box.max.x)),
      -Math.max(Math.abs(box.min.y), Math.abs(box.max.y)),
      -Math.max(Math.abs(box.min.z), Math.abs(box.max.z))
    ),
    new THREE.Vector3(
      Math.max(Math.abs(box.min.x), Math.abs(box.max.x)),
      Math.max(Math.abs(box.min.y), Math.abs(box.max.y)),
      Math.max(Math.abs(box.min.z), Math.abs(box.max.z))
    )
  );
};
