import * as THREE from "three";

export class Polygon {
  vertices: THREE.Vector2[];

  constructor(vertices: THREE.Vector2[]) {
    this.vertices = vertices;
  }

  /**
   * Returns the distance from the polygon to a point
   */
  distanceToPoint(point: THREE.Vector2 | THREE.Vector3) {
    const line = new THREE.Line3();
    const p1 = new THREE.Vector3().set(point.x, point.y, 0);
    const p2 = new THREE.Vector3();
    let minDistance = Infinity;
    for (let i = 0; i < this.vertices.length; i++) {
      const j = (i + 1) % this.vertices.length;
      line.start.set(this.vertices[i].x, this.vertices[i].y, 0);
      line.end.set(this.vertices[j].x, this.vertices[j].y, 0);
      line.closestPointToPoint(p1, true, p2);
      const distance = p1.distanceTo(p2);
      minDistance = Math.min(minDistance, distance);
    }
    return minDistance;
  }

  /**
   * Checks if a point is inside the polygon
   */
  containsPoint(point: THREE.Vector2 | THREE.Vector3) {
    if (this.vertices.length < 3) return false;
    return this.vertices.reduce((inside, p0, index, vs) => {
      const p1 = vs[(index + 1) % vs.length];
      const intersect =
        p0.y > point.y != p1.y > point.y &&
        point.x < ((p1.x - p0.x) * (point.y - p0.y)) / (p1.y - p0.y) + p0.x;
      return intersect ? !inside : inside;
    }, false);
  }

  // Check if any point is inside the polygon
  intersectsBox(box: THREE.Box2) {
    if (this.vertices.some((p) => box.containsPoint(p))) {
      return true;
    }

    const p = new THREE.Vector2();
    for (let i = 0; i < 2; i++) {
      for (let j = 0; j < 2; j++) {
        for (let k = 0; k < 2; k++) {
          const x = (i === 0 ? box.min : box.max).x;
          const y = (j === 0 ? box.min : box.max).y;
          if (this.containsPoint(p.set(x, y))) {
            return true;
          }
        }
      }
    }

    return false;
  }
}
