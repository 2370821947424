import React, { useCallback, useState } from "react";
import { useKey, useKeyPress } from "react-use";

// import { BrushSelectionTool } from "./BrushSelectionTool";
import { LassoSelectionTool } from "./LassoSelectionTool";
import { PointerSelectionTool } from "./PointerSelectionTool";
import { useSelectionStore } from "~stores/selection";
import { DatasetGeometry } from "~dataset/DatasetGeometry";
import { useShortcut } from "~hooks/useShortcut";
import { useRegisterTool, useToolStore } from "~stores/tool";
import { useCanvasGesture } from "~hooks/useCanvasGesture";
import { useCameraControls } from "~hooks/useCameraControls";
import { useThree } from "@react-three/fiber";

const BRUSH_KEY = "b";
const LASSO_KEY = "l";

export function SelectionTools({
  geometry,
  enabled = true,
}: {
  geometry: DatasetGeometry;
  enabled?: boolean;
}) {
  const { currentTool, setTool } = useToolStore();
  const { active: isCameraActive } = useCameraControls();

  useRegisterTool("pointer");
  useRegisterTool("lasso");

  useShortcut(LASSO_KEY, () =>
    currentTool === "lasso" ? setTool("pointer") : setTool("lasso")
  );

  // useShortcut(BRUSH_KEY, () =>
  //   currentTool === "brush" ? setTool("pointer") : setTool("brush")
  // );

  const [shiftPressed] = useKeyPress("Shift");
  const [altPressed] = useKeyPress("Alt");

  const { invalidate } = useThree();
  const { controls } = useCameraControls();

  const [dragging, setDragging] = useState(false);

  useCanvasGesture({
    onDragStart: () => {
      // console.log("start drag", controls?.active);
      setDragging(true);
    },
    onDragEnd: () => setDragging(false),
  });

  const selection = useSelectionStore();

  // Escape key to switch back to pointer-lasso
  useKey("Escape", () => {
    selection.setSelectedIds([]);
    invalidate(2);
  });

  // Selections
  const handleSelect = useCallback(
    (ids: number[]) => {
      if (altPressed) {
        selection.removeSelectedIds(ids);
      } else if (shiftPressed) {
        selection.addSelectedIds(ids);
      } else {
        selection.setSelectedIds(ids);
      }
    },
    [shiftPressed, altPressed]
  );

  const handleHover = useCallback((id: number) => {
    selection.setHighlightIds(id === -1 ? [] : [id]);
  }, []);

  // console.log('isCameraActive', isCameraActive);
  if (!enabled || isCameraActive) {
    return null;
  }

  if (currentTool === "pointer") {
    return (
      <>
        <PointerSelectionTool
          geometry={geometry}
          onHover={handleHover}
          onSelect={handleSelect}
        />
      </>
    );
  }

  if (currentTool === "lasso") {
    return <LassoSelectionTool geometry={geometry} onSelect={handleSelect} />;
  }

  return null;
}
