import { StopWords } from "~config/words";

const stopWordsRegex = new RegExp(
  `\\b(${Array.from(StopWords).join("|")})\\b`,
  "gi"
);

export function getMinMax(values: ArrayLike<number>): [number, number] {
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < values.length; i++) {
    if (isNaN(values[i])) continue;
    min = Math.min(min, values[i]);
    max = Math.max(max, values[i]);
  }
  return [min, max];
}

export function normalizeArray(
  values: Float32Array,
  min?: number,
  max?: number
) {
  if (min === undefined || max === undefined) {
    [min, max] = getMinMax(values);
  }
  const invRange = 1 / (max - min);
  for (let i = 0; i < values.length; i++) {
    values[i] = (values[i] - min) * invRange;
  }
  return values;
}

export function extractWords(text: string): string[] {
  return (
    text
      .toLowerCase()
      .match(/\b\w+\b/g)
      ?.filter((word) => word.length > 1 && !StopWords.has(word)) ?? []
  );
}

export function transpose(...values: Float32Array[]): Float32Array {
  const count = values[0].length;
  const result = new Float32Array(values.length * count);
  for (let i = 0; i < count; i++) {
    for (let j = 0; j < values.length; j++) {
      result[i * values.length + j] = values[j][i];
    }
  }
  return result;
}

// Finds the indices of the top values in an array without sorting the array
export function findTop(values: Iterable<number>, count: number): number[] {
  const result = new Array<number>(count);
  const top = new Array<number>(count).fill(-Infinity);

  let n = 0;
  for (const value of values) {
    let j = 0;
    while (j < count && value < top[j]) {
      j++;
    }
    if (j < count) {
      for (let k = count - 1; k > j; k--) {
        top[k] = top[k - 1];
        result[k] = result[k - 1];
      }
      top[j] = value;
      result[j] = n;
    }
    n++;
  }
  return result;
}
