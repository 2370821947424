import { Tooltip, Stack, Chip } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDataStore } from "~stores/data";
import { useKeyPress } from "react-use";
import { useSelectionStore } from "~stores/selection";

function TopWordChip({
  word,
  rate,
  active,
  onClick,
  onHover,
  onLeave,
}: {
  word: string;
  rate?: number;
  active?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  onLeave?: () => void;
}) {
  return (
    <Tooltip title={`${rate?.toFixed(2)}`} placement="top">
      <Chip
        label={word}
        onClick={onClick}
        onMouseEnter={onHover}
        onMouseLeave={onLeave}
        sx={{
          fontWeight: active ? "bold" : "normal",
        }}
      />
    </Tooltip>
  );
}

export function TopWords() {
  const [shiftPressed] = useKeyPress("Shift");
  const [altPressed] = useKeyPress("Alt");

  const { dataset } = useDataStore();
  const { selectedIds, searchText, setSearchText } = useSelectionStore();

  const [topWords, setTopWords] = useState<[string, number][]>();
  const [hoveredWord, setHoveredWord] = useState<string>();

  useEffect(() => {
    setTopWords([]);
    if (dataset && selectedIds) {
      dataset.textIndex.getTopWords(5, selectedIds).then((topWords) => {
        setTopWords(Array.from(topWords).filter(([word]) => !!word));
      });
    }
  }, [dataset, selectedIds]);

  const getSearchTerm = useCallback(
    (word: string) => {
      if (shiftPressed) {
        return `+${word}`;
      } else if (altPressed) {
        return `-${word}`;
      } else {
        return word;
      }
    },
    [shiftPressed, altPressed]
  );

  const handleSelectWord = useCallback(
    (word: string) => {
      const term = getSearchTerm(word);
      setSearchText(searchText ? `${searchText} ${term}` : term);
    },
    [searchText, shiftPressed, altPressed, setSearchText]
  );

  const searchWords = useMemo(() => {
    return searchText.split(" ").filter(Boolean);
  }, [searchText]);

  const total = selectedIds?.length || dataset?.count;

  if (!selectedIds || !total) {
    return null;
  }

  if (!topWords || topWords.length === 0) {
    return (
      <Stack direction="row" spacing={1} height={24}>
        {/* <TopWordChip active={false} word="..." /> */}
      </Stack>
    );
  }

  return (
    <Stack direction="row" spacing={1} height={24}>
      {topWords.map(([word, count]) => (
        <TopWordChip
          key={word}
          word={word}
          rate={count / total}
          active={searchWords.includes(word)}
          onClick={() => handleSelectWord(word)}
        />
      ))}
    </Stack>
  );
}
