import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

export function downloadSelection(ids: number[]) {
  const blob = new Blob([ids.join("\n")], { type: "text/plain" });
  const url = URL.createObjectURL(blob);

  // File name with selection and current timestamp
  const fileName = `selection-${Date.now()}.txt`;

  const a = document.createElement("a");
  a.href = url;
  a.download = fileName;
  a.click();

  URL.revokeObjectURL(url);
}

export function DownloadSelectionButton({
  selection,
}: {
  selection: number[];
}) {
  return (
    <Tooltip title="Download selected IDs">
      <IconButton
        onClick={() => {
          downloadSelection(selection);
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Tooltip>
  );
}
