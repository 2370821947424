import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import { useSelectionStore } from "~stores/selection";
import CloseIcon from "@mui/icons-material/ClearOutlined";

export function ClearSelectionButton() {
  const clear = useSelectionStore((s) => s.clearSelection);
  return (
    <Tooltip title="Clear selection">
      <IconButton onClick={clear} size="small">
        <CloseIcon />
      </IconButton>
    </Tooltip>
  );
}
