import React, { useRef, useCallback, useMemo } from "react";
import { Typography, Select, MenuItem } from "@mui/material";
import { BaseField } from "./BaseField";

export function SelectField<T>({
  label = "Select",
  value,
  options,
  onHover,
  onChange,
  renderValue = (value) => String(value),
}: {
  label: string;
  value: T;
  options: T[];
  onHover?: (value: T) => void;
  onChange?: (value: T) => void;
  renderValue?: (value: T) => React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>(null);

  const index = useMemo(() => options.indexOf(value), [options, value]);

  const handleChange = useCallback(
    (index: number) => {
      onChange?.(options[index]);
    },
    [options, onChange]
  );

  const renderedOptions = useMemo(
    () => options.map(renderValue),
    [renderValue, options]
  );

  return (
    <BaseField
      ref={ref}
      direction="row"
      justifyContent="space-between"
      paddingLeft={3}
      paddingRight={0}
      gap={1}
      alignItems="center"
      sx={(theme) => ({
        position: "relative",
        cursor: "pointer",
      })}
    >
      <Typography variant="body2">
        {label}
      </Typography>
      <Select
        label={label}
        value={index}
        onChange={(e) => handleChange(e.target.value as number)}
        MenuProps={{
          elevation: 6,
          sx: (theme) => ({
            "& .MuiPaper-root": {
              marginTop: theme.spacing(3),
              borderRadius: "5px",
            },
          }),
        }}
        sx={(theme) => ({
          textAlign: "right",
          flexGrow: 1,
          borderRadius: 0,
          "& fieldset": {
            display: "none",
          },
          "& .MuiSelect-select": {
            padding: 0,
            margin: 0,
            border: 0,
            fontSize: theme.typography.body2.fontSize,
            textAlign: "right",
          },
        })}
      >
        {renderedOptions.map((option, index) => (
          <MenuItem
            key={index}
            value={index}
            disableRipple
            onPointerEnter={() => onHover?.(options[index])}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
    </BaseField>
  );
}
