import { Attribute } from "~dataset/attributes/Attribute";
import { CategoryAttributeObject } from "~dataset/types";

export class CategoryAttribute extends Attribute {
  type = "category";

  values: Int32Array;
  categories: string[];

  static fromObject(obj: CategoryAttributeObject): CategoryAttribute {
    return new CategoryAttribute(obj.name, obj.values, obj.categories);
  }

  static fromTextValues(name: string, textValues: string[]): CategoryAttribute {
    const values = new Int32Array(textValues.length);

    let offset = 0;
    const uniques = new Set<string>();
    const categories = new Array<string>();
    for (let i = 0; i < textValues.length; i++) {
      if (uniques.has(textValues[i])) {
        continue;
      }
      uniques.add(textValues[i]);
      categories.push(textValues[i]);
      values[offset] = i;
      offset++;
    }

    return new CategoryAttribute(name, values, categories);
  }

  constructor(name: string, values: Int32Array, categories: string[]) {
    super(name, values.length);

    if (values instanceof Float32Array) {
      throw new Error("Values must be an Int32Array");
    }

    this.values = values;
    this.categories = categories;
  }

  getValue(index: number): number {
    return this.values[index];
  }

  getRange(): [number, number] {
    return [0, this.categories.length - 1];
  }

  getDisplayValue(index: number): string {
    return this.categories[this.getValue(index)];
  }

  toObject(): CategoryAttributeObject {
    return {
      type: "category",
      name: this.name,
      values: this.values,
      categories: this.categories,
    };
  }
}
