import { Stack, lighten, styled, alpha } from "@mui/material";

export const BaseField = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  minWidth: theme.spacing(5),
  height: theme.spacing(8),
  borderRadius: 5,
  backgroundColor: theme.palette.grey.A700,
  color: theme.palette.text.primary,
  pointerEvents: "auto",
  userSelect: "none",
  transition: theme.transitions.create([
    "color",
    "background-color",
    "border-color",
  ]),
  overflow: "hidden",
  "&:hover": {
    color: "white",
    backgroundColor: theme.palette.grey.A400,
  },
}));
