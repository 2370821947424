import { createTheme, darken, lighten, alpha } from "@mui/material/styles";

const baseColor = "#202020";

const colors = {
  paper: alpha(baseColor, 0.8),
  paperHovered: alpha(lighten(baseColor, 0.1), 0.8),
  paperActive: alpha(lighten(baseColor, 0.15), 0.8),
  border: alpha(lighten(baseColor, 0.25), 0.4),
  borderLight: alpha(lighten(baseColor, 0.35), 0.3),
  borderHovered: alpha(lighten(baseColor, 0.3), 0.9),
  borderActive: alpha(lighten(baseColor, 0.3), 0.9),
  paperButton: alpha(lighten(baseColor, 0.5), 0.2),
  paperButtonLight: alpha(lighten(baseColor, 0.4), 0.15),
  paperButtonHovered: alpha(lighten(baseColor, 0.8), 0.2),
  paperButtonActive: alpha(lighten(baseColor, 0.8), 0.25),
};

const theme = createTheme({
  typography: {
    fontSize: 10,
    fontFamily: "ui-monospace, SFMono-Regular, Menlo",
  },
  spacing: 3,
  shape: {
    borderRadius: 8,
  },
  palette: {
    mode: "dark",
    text: {
      primary: "#ccc",
      secondary: alpha("#ddd", 0.6),
      disabled: alpha("#ddd", 0.25),
    },
    primary: {
      main: "#ddd",
    },
    secondary: {
      main: "#999",
    },
    grey: {
      A100: colors.paperButtonActive,
      A200: colors.paperButtonHovered,
      A400: colors.paperButton,
      A700: colors.paperButtonLight,
      200: colors.borderActive,
      300: colors.borderHovered,
      400: colors.border,
      500: colors.paperActive,
      600: colors.paperHovered,
      700: colors.paper,
    },
    divider: colors.border,
    background: {
      default: colors.paperButton,
      paper: colors.paper,
    },
  },
});

export default createTheme(theme, {
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          pointerEvents: "auto",
          minWidth: theme.spacing(8),
          color: theme.palette.text.secondary,
          borderColor: colors.border,
          backgroundColor: colors.paper,
          backdropFilter: "blur(10px)",
          borderRadius: theme.shape.borderRadius,
          "&:hover": {
            color: theme.palette.text.primary,
            borderColor: colors.borderHovered,
            backgroundColor: colors.paperHovered,
          },
          "&:active": {
            color: theme.palette.text.primary,
            borderColor: colors.borderActive,
            backgroundColor: colors.paperActive,
          },
        },
        outlinedSecondary: {
          color: theme.palette.text.primary,
          borderColor: colors.borderActive,
          backgroundColor: colors.paperActive,
          "&:hover": {
            color: theme.palette.text.primary,
            borderColor: colors.borderActive,
            backgroundColor: colors.paperActive,
          },
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        size: "small",
        // @ts-ignore
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          color: theme.palette.text.primary,
          backgroundColor: colors.paperButtonLight,
          height: 22,
          borderRadius: 5,
          padding: 0,
          "&:hover": {
            color: theme.palette.text.primary,
            backgroundColor: colors.paperButtonHovered,
          },
          "&:active": {
            color: theme.palette.text.primary,
            backgroundColor: colors.paperButtonActive,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: `1px solid ${colors.border}`,
          backgroundImage: "none",
          backgroundColor: colors.paper,
          backdropFilter: "blur(16px)",
          pointerEvents: "auto",
          "& .MuiIconButton-root": {
            width: theme.spacing(8),
            height: theme.spacing(8),
            color: theme.palette.text.primary,
            backgroundColor: colors.paperButton,
            "&:hover": {
              color: theme.palette.text.primary,
              backgroundColor: colors.paperButtonHovered,
            },
            "&:active": {
              color: theme.palette.text.primary,
              backgroundColor: colors.paperButtonActive,
            },
          },
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 5,
      },
      styleOverrides: {
        paper: {
          border: `1px solid ${colors.borderLight}`,
          backgroundColor: colors.paper,
          pointerEvents: "auto",
        },
        list: {
          padding: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: theme.typography.fontSize,
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: colors.paperButton,
          },
          "&:active": {
            backgroundColor: colors.paperButtonHovered
          },
          "&.Mui-selected": {
            backgroundColor: colors.paperButtonHovered,
            "&:hover": {
              backgroundColor: colors.paperButtonHovered,
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: `1px solid ${colors.border}`,
          backgroundColor: colors.paper,
          backdropFilter: "blur(16px)",
          pointerEvents: "auto",
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
        followCursor: true,
      },
    },
  },
});
