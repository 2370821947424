import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { useEffect, useMemo } from "react";

export function useClipToCanvasTransform() {
  const transform = useMemo(() => new THREE.Matrix4(), []);
  const { size } = useThree();

  useEffect(() => {
    transform.compose(
      new THREE.Vector3(size.width / 2, size.height / 2, 0),
      new THREE.Quaternion(),
      new THREE.Vector3(size.width / 2, size.height / 2, 1)
    );
  }, [size.width, size.height]);

  return transform;
}
