import React from "react";
import _ from "lodash";
import { useDrop } from "react-use";

import { Dataset } from "~dataset/Dataset";
import { Attribute } from "~dataset/attributes/Attribute";
import { useDataStore } from "~stores/data";
import { parse } from "~lib/json-binary-encoder";
import { CategoryAttribute } from "~dataset/attributes/CategoryAttribute";
import { NumberAttribute } from "~dataset/attributes/NumberAttribute";

function concatArrays<T extends ArrayLike<number>>(arrays: T[]) {
  const count = _.sumBy(arrays, (a) => a.length);
  const result = new Float32Array(count);
  let offset = 0;
  arrays.forEach((a) => {
    result.set(a, offset);
    offset += a.length;
  });
  return result;
}

// Quick implementation of BS5 to Dataset conversion for debugging
function datasetFromBS5Scene(scene: any) {
  const lidar = scene?.sensors?.find((s: any) => s.type === "lidar");
  if (lidar) {
    const dataset = new Dataset();

    const frames: any[] = lidar.frames.slice(0, 10);

    const positions = concatArrays(frames.map((f) => f.points.positions));
    const count = positions.length / 3;

    const frame = NumberAttribute.zero("frame", count);
    const z = NumberAttribute.zero("z", count);

    // Fill frames attribute
    let offset = 0;
    for (let i = 0; i < frames.length; i++) {
      const frameCount = frames[i].points.positions.length / 3;
      frame.values.fill(i, offset, offset + frameCount);
      offset += frameCount;
    }

    // Fill z attribute
    for (let i = 0; i < count; i++) {
      z.values[i] = positions[i * 3 + 2];
    }

    frame.updateRange();
    z.updateRange();

    dataset.addAttribute(frame);
    dataset.addAttribute(z);
    dataset.setProjections(positions);

    return dataset;
  }

  throw new Error("No lidar sensor found");
}

function BS5SceneLoader({ onLoad }: { onLoad: (dataset: Dataset) => void }) {
  const handleLoadUrl = async (url: string) => {
    const dataset = await fetch(url)
      .then((response) => response.arrayBuffer())
      .then((buffer) => parse(buffer))
      .then((data) => datasetFromBS5Scene(data));

    onLoad(dataset);
  };

  const handleLoadFile = async (file: File) => {
    if (file.name.endsWith(".bs5")) {
      await handleLoadUrl(URL.createObjectURL(file));
    }
  };

  useDrop({
    onFiles: (files) => {
      files.forEach(handleLoadFile);
    },
  });

  return null;
}

export default React.memo(BS5SceneLoader);
