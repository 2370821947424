import React from "react";
import { useDrop } from "react-use";

import { parse } from "~lib/json-binary-encoder";
import { DatasetObject } from "~dataset/types";
import { getMinMax } from "~dataset/utils";
import { Dataset } from "~dataset/Dataset";

// Retrocompatibility with old datasets
function fixDatasetObject(obj: DatasetObject) {
  // Category: keys -> categories
  if (obj.attributes) {
    obj.attributes.forEach((a: any) => {
      if (a.type === "category" && a.keys) {
        console.warn(
          `Keys field in category attribute ${a.name} is deprecated`
        );
        a.categories = a.keys;
        delete a.keys;
      }
    });
  }

  // Text: values -> offsets
  if (obj.attributes) {
    obj.attributes.forEach((a: any) => {
      if (a.type === "text" && a.values) {
        console.warn(`Values field in text attribute ${a.name} is deprecated`);
        a.offsets = a.values;
        delete a.values;
      }
    });
  }

  // Fix normalized category values
  if (obj.attributes) {
    obj.attributes.forEach((a: any) => {
      if (a.type === "category" && a.categories) {
        if (a.values instanceof Float32Array) {
          console.warn(
            `Normalized category values in attribute ${a.name} are deprecated`
          );
          const [min, max] = getMinMax(a.values);
          const values = new Uint32Array(a.values.length);
          if (a.categories.length > 1) {
            for (let i = 0; i < a.values.length; i++) {
              values[i] = Math.round(
                ((a.values[i] - min) / (max - min)) * (a.categories.length - 1)
              );
            }
          } else {
            values.fill(0);
          }
          a.values = values;
        }
      }
    });
  }

  return obj;
}

export async function createDatasetFromUrl(url: string) {
  const dataset = await fetch(url)
    .then((response) => response.arrayBuffer())
    .then((buffer) => parse(buffer) as DatasetObject)
    .then((obj) => Dataset.fromObject(fixDatasetObject(obj)));
  return dataset;
}

export function DatasetLoader({
  onLoad,
}: {
  onLoad: (dataset: Dataset) => void;
}) {
  const handleLoadFile = async (file: File) => {
    if (file.name.endsWith(".bin")) {
      const url = URL.createObjectURL(file);
      const dataset = await createDatasetFromUrl(url);
      onLoad(dataset);
    }
  };

  useDrop({
    onFiles: (files) => {
      files.forEach(handleLoadFile);
    },
  });

  return null;
}

export default React.memo(DatasetLoader);
