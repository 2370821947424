import _ from "lodash";
import { Attribute } from "~dataset/attributes/Attribute";
import { NumberAttributeObject } from "~dataset/types";
import { getMinMax } from "~dataset/utils";

export class NumberAttribute extends Attribute {
  type = "number";

  values: Float32Array;
  range: [number, number];

  static fromObject(obj: NumberAttributeObject): NumberAttribute {
    return new NumberAttribute(obj.name, obj.values);
  }

  static fromArray(name: string, values: ArrayLike<number>): NumberAttribute {
    return new NumberAttribute(name, new Float32Array(values));
  }

  static zero(name: string, count: number) {
    return new NumberAttribute(name, new Float32Array(count));
  }

  constructor(name: string, values: Float32Array, range?: [number, number]) {
    super(name, values.length);

    this.values = values;
    this.range = range || getMinMax(values);
  }

  map(fn: (v: number) => number): this {
    this.values = this.values.map(fn);
    return this;
  }

  updateRange() {
    this.range = getMinMax(this.values);
  }

  getValue(index: number): number {
    return this.values[index];
  }

  getRange(): [number, number] {
    return this.range;
  }

  getDisplayValue(index: number): string {
    return this.getValue(index).toFixed(4);
  }

  toObject(): NumberAttributeObject {
    return {
      type: "number",
      name: this.name,
      values: this.values,
    };
  }
}
