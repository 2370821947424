import React from "react";

import {
  Button,
  IconButton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from "@mui/material";
import { useToolStore } from "~stores/tool";
import { useShortcut } from "~hooks/useShortcut";

import LassoIcon from "~assets/icons/lasso.svg";
import PointerIcon from "~assets/icons/pointer.svg";
import { TextSearchTool } from "./tools/TextSearchTool";

import { SettingsPanel } from "./panels/SettingsPanel";
import { FloatingButton } from "./ui/FloatingButton";
import { ColorPanel } from "./panels/ColorPanel";

export function ToolButton({
  name,
  title,
  hotkey,
  children,
}: {
  name: string;
  hotkey: string;
  title: string;
  children: React.ReactNode;
}) {
  const { currentTool, setTool, tools } = useToolStore();
  const selected = currentTool === name;

  useShortcut(hotkey, () => setTool(name));

  if (!tools.includes(name)) {
    return null;
  }

  return (
    <Tooltip title={title} arrow>
      <div>
        <FloatingButton selected={selected} onClick={() => setTool(name)}>
          {children}
        </FloatingButton>
      </div>
    </Tooltip>
  );
}

export function Toolbar() {
  return (
    <Stack width="100%" direction="row" justifyContent="space-between">
      <Stack direction="row" spacing={1.5} minHeight={60}>
        <ToolButton name="pointer" title="Pointer tool (p)" hotkey="p">
          <SvgIcon inheritViewBox component={PointerIcon} />
        </ToolButton>
        <ToolButton name="lasso" title="Lasso tool (l)" hotkey="l">
          <SvgIcon inheritViewBox component={LassoIcon} />
        </ToolButton>
        <TextSearchTool />
      </Stack>
      <Stack direction="row" spacing={1.5} minHeight={60}>
        <SettingsPanel />
      </Stack>
    </Stack>
  );
}
