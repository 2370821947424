import { useKey } from "react-use";

export function useShortcut(
  key: string,
  onKeyDown?: (event: KeyboardEvent) => void,
  onKeyUp?: (event: KeyboardEvent) => void
) {
  useKey(
    key,
    (event: KeyboardEvent) => {
      if (event.repeat) return;
      onKeyDown?.(event);
    },
    { event: "keydown" },
    [key, onKeyDown]
  );
  useKey(
    key,
    (event: KeyboardEvent) => {
      if (event.repeat) return;
      onKeyUp?.(event);
    },
    { event: "keyup" },
    [key, onKeyUp]
  );
}
