import React from "react";
import { Snackbar, Stack, Typography } from "@mui/material";

export function LoaderSnackbar({
  progress = 0,
  title = "Loading...",
}: {
  progress?: number;
  title?: string;
}) {
  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{
        "& .MuiSnackbarContent-root": {
          background: "rgba(40,40,40,0.3)",
          backdropFilter: "blur(2px)",
          opacity: 0.9,
          pointerEvents: "auto",
        },
      }}
      message={
        <Stack
          width={250}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography variant="body2" color="text.primary">
            {title}
          </Typography>
          {progress > 0 && (
            <Typography variant="body2" color="text.primary">
              {(progress * 100).toFixed(0)}%
            </Typography>
          )}
        </Stack>
      }
    />
  );
}
