import { useThree } from "@react-three/fiber";
import { useGesture } from "react-use-gesture";
import { Handlers, UseGestureConfig } from "react-use-gesture/dist/types";

export function useCanvasGesture(handlers: Handlers, config?: UseGestureConfig) {
  const { gl } = useThree();
  return useGesture(handlers, {
    domTarget: gl.domElement,
    transform: ([x, y]) => [x, gl.domElement.clientHeight - y],
    ...config,
  });
}
