import React, { useCallback, useEffect, useRef, useState } from "react";

import { useDataStore } from "~stores/data";
import { useSelectionStore } from "~stores/selection";
import { SearchButton } from "~components/ui/SearchButton";
import { Tooltip } from "@mui/material";
import { useKey } from "react-use";

export function TextSearchTool() {
  const { loading, dataset } = useDataStore();
  const searchText = useSelectionStore((s) => s.searchText);
  const setSearchText = useSelectionStore((s) => s.setSearchText);
  const setSelectedIds = useSelectionStore((s) => s.setSelectedIds);

  const [text, setText] = useState<string>("");

  const handleSearch = useCallback(
    async (value: string) => {
      const selectedIds = useSelectionStore.getState().selectedIds;
      const results = await dataset?.textIndex.search(
        value,
        selectedIds.length > 0 ? selectedIds : undefined
      );
      if (results) {
        setSelectedIds(results);
      }
    },
    [dataset, setSelectedIds]
  );

  useEffect(() => {
    setText(searchText);
    handleSearch(searchText);
  }, [searchText, handleSearch]);

  const handleClear = useCallback(() => {
    setText("");
    setSearchText("");
  }, [setSearchText]);

  useKey("Escape", handleClear);

  if (!dataset) {
    return null;
  }

  const title = loading ? "Creating text index..." : "Search text";

  return (
    <Tooltip arrow title={title}>
      <span>
        <SearchButton
          disabled={loading}
          value={text}
          onChange={setText}
          onSubmit={setSearchText}
          onClear={handleClear}
        />
      </span>
    </Tooltip>
  );
}
