import React, { useEffect, useMemo, useState } from "react";

import { Card, Stack, Typography } from "@mui/material";
import { useSettingsStore } from "~stores/settings";

import { useDataStore } from "~stores/data";

import { CategoryAttribute } from "~dataset/attributes/CategoryAttribute";
import { NumberAttribute } from "~dataset/attributes/NumberAttribute";

// TODO: User non texture based color palette
const getPaletteColors = async (imagePath: string) => {
  const offscreenCanvas = new OffscreenCanvas(1, 1);
  const context = offscreenCanvas.getContext("2d");
  const image = new Image();

  image.src = imagePath;

  await image.decode();

  offscreenCanvas.width = image.width;
  offscreenCanvas.height = image.height;

  if (!context) {
    return [];
  }

  context.drawImage(image, 0, 0);

  const firstRowColors = [];
  const imageData = context.getImageData(0, 0, image.width, 1);

  for (let i = 0; i < imageData.width; i++) {
    const startIndex = i * 4;
    const r = imageData.data[startIndex];
    const g = imageData.data[startIndex + 1];
    const b = imageData.data[startIndex + 2];

    const color = `rgb(${r}, ${g}, ${b})`;
    firstRowColors.push(color);
  }

  return firstRowColors;
};

function ColorItem({ name, color }: { name: string; color: string }) {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Typography variant="body2">{name}</Typography>
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 4,
          backgroundColor: color,
        }}
      />
    </Stack>
  );
}

export function ColorPanel() {
  const { dataset, colorAttribute } = useDataStore();
  const { colorPalette, showColorLegend, setColorPaletteLength } = useSettingsStore();

  const [paletteColors, setPaletteColors] = useState<string[] | null>(null);

  useEffect(() => {
    if (!colorPalette) {
      setPaletteColors(null);
    }
    getPaletteColors(colorPalette.texture).then(setPaletteColors);
  }, [colorPalette]);

  useEffect(() => {
    if (paletteColors) setColorPaletteLength(paletteColors.length);
  }, [paletteColors, setColorPaletteLength]);

  const colors = useMemo(() => {
    if (!dataset || !colorAttribute || !paletteColors) {
      return null;
    }

    const attribute = dataset.getAttributeByName(colorAttribute);
    if (attribute instanceof CategoryAttribute) {
      return attribute.categories.map((category, index) => {
        if (attribute.categories.length === 1) {
          return {
            name: category,
            color: paletteColors[paletteColors.length - 1],
          };
        }
        let colorIndex = Math.floor(
          (index * (paletteColors.length)) /
            (attribute.categories.length - 1)
        );
        if (colorIndex >= paletteColors.length) colorIndex = paletteColors.length - 1;
        return {
          name: category,
          color: paletteColors[colorIndex],
        };
      });
    }
    if (attribute instanceof NumberAttribute) {
      const [min, max] = attribute.getRange();
      return paletteColors.map((color, index) => {
        const value = min + (max - min) * (index / (paletteColors.length - 1));
        return {
          name: value.toFixed(2),
          color,
        };
      });
    }
  }, [colorAttribute, paletteColors]);

  if (!showColorLegend || !dataset || !colors) {
    return null;
  }

  return (
    <Stack>
      <Stack padding={2} spacing={1} alignItems="end">
        {colors.map(({ name, color }, index) => (
          <ColorItem key={index} name={name} color={color} />
        ))}
      </Stack>
    </Stack>
  );
}
