import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";
import React, { useCallback, useMemo, useState } from "react";
import { useSceneToCanvasTransform } from "../../hooks/useSceneToCanvasTransform";
import { useCanvasGesture } from "~hooks/useCanvasGesture";
import { Cursor } from "~components/helpers/Cursor";
import { useClipToCanvasTransform } from "~hooks/useClipToCanvasTransform";
import { NearestProjectedPointQuery } from "~dataset/queries/NearestProjectedPointQuery";
import { DatasetGeometry } from "~dataset/DatasetGeometry";
import { useUnmount } from "react-use";

export function PointerSelectionTool({
  geometry,
  radius = 10,
  onSelect,
  onHover,
}: {
  geometry: DatasetGeometry;
  radius?: number;
  onSelect?: (ids: number[]) => void;
  onHover?: (id: number) => void;
}) {
  const sceneToCanvas = useSceneToCanvasTransform();
  const clipToCanvas = useClipToCanvasTransform();

  const target = useMemo(() => new THREE.Vector3(), []);
  const lastPointer = useMemo(() => new THREE.Vector2(), []);
  const [hovered, setHovered] = useState(-1);

  const [dragging, setDragging] = useState(false);

  const { pointer, invalidate } = useThree();

  const checkHover = useCallback(() => {
    if (!lastPointer.equals(pointer)) {
      lastPointer.copy(pointer);

      const query = new NearestProjectedPointQuery({
        boxTree: geometry.boxTree,
        points: geometry.positions,
        target: target.set(pointer.x, pointer.y, 0).applyMatrix4(clipToCanvas),
        projection: sceneToCanvas,
        maxDistance: radius,
      });

      const result = query.run();
      return result[0] ?? -1;
    }
    return -1;
  }, [dragging, hovered, lastPointer, pointer, radius, sceneToCanvas]);

  useCanvasGesture({
    onDragStart: ({ buttons }) => {
      if (hovered > -1 && buttons === 1) {
        onSelect?.([hovered]);
      }
    },
    onDrag: ({ distance }) => {
      if (distance > 2 && !dragging) {
        setDragging(true);
      }
    },
    onDragEnd: () => {
      setDragging(false);
      setHovered(-1);
      onHover?.(-1);
    },
    onMouseMove: () => {
      if (dragging) return;
      const index = checkHover();
      if (index !== hovered) {
        setHovered(index);
        onHover?.(index);
        invalidate(2);
      }
    },
  });

  useUnmount(() => {
    setDragging(false);
    setHovered(-1);
    onHover?.(-1);
  });

  return (
    <Cursor
      value={hovered > -1 && !dragging ? "pointer" : undefined}
      priority={5}
    />
  );
}
