import _ from "lodash";
import { create } from "zustand";

interface SelectionStore {
  selectedIds: number[];
  setSelectedIds: (ids: number[]) => void;
  addSelectedIds: (ids: number[]) => void;
  removeSelectedIds: (ids: number[]) => void;
  clearSelection: () => void;

  highlightIds: number[];
  setHighlightIds: (ids: number[]) => void;
  clearHighlight: () => void;

  searchText: string;
  setSearchText: (text: string) => void;
}

export const useSelectionStore = create<SelectionStore>((set) => ({
  selectedIds: [],
  setSelectedIds: (ids) => set({ selectedIds: ids }),
  addSelectedIds: (ids) =>
    set((state) => ({ selectedIds: _.uniq([...state.selectedIds, ...ids]) })),
  removeSelectedIds: (ids) =>
    set((state) => ({ selectedIds: _.difference(state.selectedIds, ids) })),
  clearSelection: () => {
    set({ selectedIds: [] });
    set({ highlightIds: [] });
    set({ searchText: "" });
  },

  highlightIds: [],
  setHighlightIds: (ids) => set({ highlightIds: ids }),
  clearHighlight: () => set({ highlightIds: [] }),

  searchText: "",
  setSearchText: (text) => set({ searchText: text }),
}));
