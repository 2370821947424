import * as THREE from "three";
import React, { useCallback, useState } from "react";

import { CanvasHud } from "../helpers/CanvasHud";
import { LineDrawer } from "../helpers/LineDrawer";

import { DatasetGeometry } from "~dataset/DatasetGeometry";
import { ProjectedPolygonQuery } from "~dataset/queries/ProjectedPolygonQuery";
import { useSceneToCanvasTransform } from "~hooks/useSceneToCanvasTransform";
import { useKeyPress } from "react-use";

import lassoCursor from "~/assets/cursors/lasso.png";
import lassoAddCursor from "~/assets/cursors/lasso-add.png";
import lassoDiffCursor from "~/assets/cursors/lasso-diff.png";
import { Cursor } from "~components/helpers/Cursor";

export function LassoToolCursor() {
  const [shiftPressed] = useKeyPress("Shift");
  const [altPressed] = useKeyPress("Alt");

  if (altPressed) {
    return <Cursor value={`url(${lassoDiffCursor}), auto`} />;
  }
  if (shiftPressed) {
    return <Cursor value={`url(${lassoAddCursor}), auto`} />;
  }
  return <Cursor value={`url(${lassoCursor}), auto`} />;
}

export function LassoSelectionTool({
  geometry,
  onSelect,
  enabled = true,
}: {
  geometry: DatasetGeometry;
  onSelect?: (ids: number[]) => void;
  lineWidth?: number;
  enabled?: boolean;
  minDistance?: number;
  color?: THREE.ColorRepresentation;
}) {
  const [vertices, setVertices] = useState<THREE.Vector2[]>([]);

  const sceneToCanvas = useSceneToCanvasTransform();

  const handleSubmit = useCallback(() => {
    if (vertices.length < 3) {
      return;
    }

    const query = new ProjectedPolygonQuery({
      boxTree: geometry.boxTree,
      points: geometry.positions,
      vertices: vertices,
      projection: sceneToCanvas,
    });

    const result = query.run();
    onSelect?.(result);
  }, [vertices, onSelect]);

  if (!enabled) {
    return null;
  }

  return (
    <CanvasHud>
      <LineDrawer
        vertices={vertices}
        onChange={setVertices}
        onSubmit={handleSubmit}
        width={1}
        opacity={1.0}
      />
      <LassoToolCursor />
    </CanvasHud>
  );
}
