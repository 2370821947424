import * as THREE from "three";
import React, { useCallback, useState } from "react";
import { Line } from "@react-three/drei";
import { useCanvasGesture } from "~hooks/useCanvasGesture";

export function LineDrawer({
  width = 2,
  color = "white",
  opacity = 1,
  enabled = true,
  minDistance = 5,
  vertices,
  onChange,
  onSubmit,
}: {
  width?: number;
  color?: THREE.ColorRepresentation;
  opacity?: number;
  minDistance?: number;
  enabled?: boolean;
  vertices: THREE.Vector2[];
  onChange?: (vertices: THREE.Vector2[]) => void;
  onSubmit?: (vertices: THREE.Vector2[]) => void;
}) {
  const handleChange = useCallback(
    (vertices: THREE.Vector2[]) => {
      onChange?.(vertices);
    },
    [onChange]
  );

  useCanvasGesture(
    {
      onDragStart: ({ event, buttons, xy }) => {
        if (buttons === 1) {
          const vertex = new THREE.Vector2(...xy);
          handleChange([vertex]);
          // event.stopPropagation();
        }
      },
      onDrag: ({ buttons, xy }) => {
        if (buttons === 1) {
          const vertex = new THREE.Vector2(...xy);

          const lastVertex = vertices[vertices.length - 1];
          if (lastVertex && lastVertex.distanceTo(vertex) < minDistance) {
            return;
          }

          handleChange([...vertices, vertex]);
        }
      },
      onDragEnd: () => {
        onSubmit?.(vertices);
        handleChange([]);
      },
    },
    { enabled, eventOptions: { capture: true } }
  );

  if (!enabled || vertices.length < 1) {
    return null;
  }

  return (
    <Line
      points={vertices}
      color={color}
      lineWidth={width}
      depthWrite={true}
      depthFunc={THREE.LessDepth}
      opacity={opacity}
      transparent={opacity < 1}
    />
  );
}
