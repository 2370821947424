import React from "react";
import { Canvas } from "@react-three/fiber";
import DatasetView from "./views/DatasetView";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "~/theme";
import MainCameraControls from "~components/controls/MainCameraControls";
import { useDataStore } from "~stores/data";
import { Dataset } from "~dataset/Dataset";
import { createDatasetFromUrl } from "./loaders/DatasetLoader";

export function EmbeddingsVisualizer() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Canvas frameloop="demand">
        <MainCameraControls />
        <axesHelper />
        <DatasetView />
      </Canvas>
    </ThemeProvider>
  );
}

export async function loadDataset(dataset: Dataset) {
  return useDataStore.getState().loadDataset(dataset);
}

export async function loadDatasetUrl(url: string) {
  const dataset = await createDatasetFromUrl(url);
  return loadDataset(dataset);
}
