import { create } from "zustand";
import { Dataset } from "~dataset/Dataset";
import { DatasetGeometry } from "~dataset/DatasetGeometry";
import * as THREE from "three";

const defaultColorAttributes = [
  "color",
  "label",
  "category",
  "project_name",
  "z",
  "rating (1-5)",
];

interface DataStore {
  dataset?: Dataset;
  loadDataset: (dataset: Dataset) => Promise<void>;

  geometry?: DatasetGeometry;
  createGeometry: (dataset: Dataset) => void;

  colorAttribute?: string;
  setColorAttribute(name: string): void;

  loading?: boolean;

  defaultCamera?: DefaultCamera;
  setDefaultCameraForDataset(dataset: Dataset): void;
}

interface DefaultCamera {
  defaultX?: number;
  defaultY?: number;
  defaultBox?: THREE.Box3;
  defaultPaddingX?: number;
  defaultPaddingY?: number;
}

export const useDataStore = create<DataStore>((set, get) => ({
  count: 0,
  attributeNames: [],

  loading: false,

  async loadDataset(dataset: Dataset) {
    set({ dataset, loading: true });

    console.log(dataset);

    // Create geometry and indices
    get().createGeometry(dataset);
    await dataset.buildIndices();

    // Set default x and y
    get().setDefaultCameraForDataset(dataset);

    set({ loading: false });
  },

  createGeometry(dataset: Dataset) {
    if (dataset.projections) {
      const geometry = new DatasetGeometry(dataset.projections.values);

      const colorAttribute = defaultColorAttributes.find((name) =>
        dataset.attributes.find((a) => a.name === name)
      );

      set({ geometry });

      if (colorAttribute) {
        get().setColorAttribute(colorAttribute);
      }
    }
  },

  setColorAttribute(name: string) {
    const { dataset, geometry } = get();

    if (!geometry || !dataset) return;

    const attribute = dataset.attributes.find((a) => a.name === name);

    if (attribute && attribute.values) {
      geometry.setColorAttribute(name, attribute.values, attribute.getRange());
      set({ colorAttribute: name });
    } else {
      geometry.setColorAttribute();
      set({ colorAttribute: undefined });
    }
  },

  setDefaultCameraForDataset(dataset: Dataset) {
    const average = (a: number[]) =>
      a.reduce((x: number, y: number) => x + y) / a.length;
    const xAttribute = dataset.attributes.find((a) => a.name === "x");
    const yAttribute = dataset.attributes.find((a) => a.name === "y");
    if (xAttribute?.values?.length) {
      const x = average([...xAttribute.values]);
      set({ defaultCamera: { ...get().defaultCamera, defaultX: x } });
    }
    if (yAttribute?.values?.length) {
      const y = average([...yAttribute.values]);
      set({ defaultCamera: { ...get().defaultCamera, defaultY: y } });
    }
    if (xAttribute?.getRange() && yAttribute?.getRange()) {
      set({
        defaultCamera: {
          ...get().defaultCamera,
          defaultBox: new THREE.Box3(
            new THREE.Vector3(
              xAttribute.getRange()[0],
              yAttribute.getRange()[0],
            ),
            new THREE.Vector3(
              xAttribute.getRange()[1],
              yAttribute.getRange()[1]
            )
          ),
          defaultPaddingX:
            (xAttribute.getRange()[1] - xAttribute.getRange()[0]) / 2,
          defaultPaddingY:
            (yAttribute.getRange()[1] - yAttribute.getRange()[0]) / 2,
        },
      });
    }
  },
}));
