import { create } from "zustand";
import ColorPalettes, { ColorPalette } from "~config/palettes";

interface SettignsStore {
  pointSize: number;
  setPointSize: (pointSize: number) => void;

  colorPalette: ColorPalette;
  setColorPalette: (colorPalette: ColorPalette) => void;

  colorPaletteLength: number;
  setColorPaletteLength: (colorPaletteLength: number) => void;

  showColorLegend: boolean;
  setShowColorLegend: (showColorLegend: boolean) => void;
}

export const useSettingsStore = create<SettignsStore>((set) => ({
  pointSize: 6,
  setPointSize: (pointSize) => set({ pointSize }),

  colorPalette: ColorPalettes[3],
  setColorPalette: (colorPalette) => set({ colorPalette }),

  colorPaletteLength: 0,
  setColorPaletteLength: (colorPaletteLength) => set({ colorPaletteLength }),

  showColorLegend: true,
  setShowColorLegend: (showColorLegend) => set({ showColorLegend }),
}));
