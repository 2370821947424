import * as THREE from "three";
import { BoxTreeQuery, BoxTreeQueryParams } from "./BoxTreeQuery";

interface NearestProjectedPointQueryParams extends BoxTreeQueryParams {
  target: THREE.Vector3;
  projection: THREE.Matrix4;
  maxDistance?: number;
}

export class NearestProjectedPointQuery extends BoxTreeQuery {
  target: THREE.Vector3;
  projection: THREE.Matrix4;
  maxDistance: number;

  constructor(params: NearestProjectedPointQueryParams) {
    super(params);
    this.target = params.target;
    this.projection = params.projection;
    this.maxDistance = params.maxDistance || 10;
  }

  run(previous?: number[]) {
    const box = new THREE.Box3();
    const point = new THREE.Vector3();
    let minDistance = Infinity;

    point.fromArray(this.points, 0);
    point.applyMatrix4(this.projection);

    const result: number[] = [];
    this.boxTree.traverse((node) => {
      box.copy(node.boundingBox).applyMatrix4(this.projection);
      const boxDistance = box.distanceToPoint(this.target);

      if (boxDistance > this.maxDistance && box.max.z < 1) {
        return false;
      }

      if (node.children.length === 0) {
        for (let i = 0; i < node.count; i++) {
          point.fromArray(this.points, node.index[i] * 3);
          point.applyMatrix4(this.projection);
          const distance = point.distanceTo(this.target);
          if (distance < minDistance) {
            minDistance = distance;
            if (minDistance < this.maxDistance) {
              result[0] = node.index[i];
            }
          }
        }
      }
    });

    return this.merge(result, previous);
  }
}
