import React from "react";
import { useThree } from "@react-three/fiber";
import { Hud, OrthographicCamera } from "@react-three/drei";

export function CanvasHud({
  children,
  renderPriority = 1,
}: {
  children: React.ReactNode;
  renderPriority?: number;
}) {
  const size = useThree(state => state.size);
  return (
    <Hud renderPriority={renderPriority}>
      <OrthographicCamera
        makeDefault
        left={0}
        bottom={0}
        right={size.width}
        top={size.height}
        far={1000}
        near={-1000}
      />
      {children}
    </Hud>
  );
}
