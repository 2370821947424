import * as THREE from "three";
import { BoxTreeQuery, BoxTreeQueryParams } from "./BoxTreeQuery";
import { Polygon } from "~math/Polygon";

interface ProjectedPolygonQueryParams extends BoxTreeQueryParams {
  vertices: THREE.Vector2[];
  projection: THREE.Matrix4;
}

export class ProjectedPolygonQuery extends BoxTreeQuery {
  vertices: THREE.Vector2[];
  projection: THREE.Matrix4;

  constructor(params: ProjectedPolygonQueryParams) {
    super(params);
    this.vertices = params.vertices;
    this.projection = params.projection;
  }

  run(previous?: number[]) {
    const polygon = new Polygon(this.vertices);
    const point = new THREE.Vector3();
    const box3 = new THREE.Box3();
    const box2 = new THREE.Box2();

    // let linearCount = 0;
    // let nodeCount = 0;

    const indices: number[] = [];
    this.boxTree.traverse((node) => {
      box3.copy(node.boundingBox).applyMatrix4(this.projection);
      box2.setFromPoints([
        new THREE.Vector2(box3.min.x, box3.min.y),
        new THREE.Vector2(box3.max.x, box3.max.y),
      ]);

      if (!polygon.intersectsBox(box2)) {
        return false;
      }

      if (node.children.length > 0) {
        return true;
      }

      // nodeCount++;
      for (let i = 0; i < node.count; i++) {
        const index = node.index[i];
        point.fromArray(this.points, index * 3);
        point.applyMatrix4(this.projection);

        // linearCount++;
        if (polygon.containsPoint(point)) {
          indices.push(index);
        }
      }
      return false;
    });

    return this.merge(indices, previous);
  }
}
