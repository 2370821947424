import React, { useCallback, useEffect } from "react";

import { PointsView } from "./PointsView";
import { useSelectionStore } from "~stores/selection";
import { useDataStore } from "~stores/data";
import { useSettingsStore } from "~stores/settings";
import { useTexture } from "@react-three/drei";
import { useCameraControls } from "~hooks/useCameraControls";
import { useKey } from "react-use";
import { computeCenteredBoundingBox } from "~math/utils";
import { SelectionTools } from "../tools/SelectionTools";
import { useThree } from "@react-three/fiber";

function DatasetView() {
  const { invalidate } = useThree();
  const geometry = useDataStore((s) => s.geometry);
  const colorAttribute = useDataStore((s) => s.colorAttribute);

  const selection = useSelectionStore();

  const { colorPalette, pointSize } = useSettingsStore();
  const colorsTexture = useTexture(colorPalette.texture);

  const { controls } = useCameraControls();

  const { defaultCamera } = useDataStore();

  const handleFitToBox = useCallback(() => {
    const box = geometry?.boxTree?.root.boundingBox ?? geometry?.boundingBox;
    const { defaultX, defaultY, defaultBox, defaultPaddingX, defaultPaddingY } = defaultCamera ?? {};
    if (defaultX && defaultY && controls) {
      controls.setTarget(defaultX, defaultY, 0, true);
    } 
    if (defaultBox && controls) {
      controls.fitToBox(defaultBox, true, {
        paddingTop: defaultPaddingY,
        paddingLeft: defaultPaddingX,
        paddingBottom: defaultPaddingY,
        paddingRight: defaultPaddingX,
      });
    } else if (box && controls) {
      controls.rotatePolarTo(0, true);
      controls.fitToBox(computeCenteredBoundingBox(box), true);
    }
  }, [geometry, defaultCamera, controls]);

  useEffect(handleFitToBox, [geometry, defaultCamera, controls]);
  useKey("f", handleFitToBox, undefined, [handleFitToBox]);

  // Invalidate when selection changes
  useEffect(invalidate, [
    geometry,
    selection.selectedIds,
    selection.highlightIds,
    colorPalette,
    colorAttribute,
  ]);

  if (!geometry) {
    return null;
  }

  return (
    <group>
      <PointsView
        geometry={geometry}
        pointSize={pointSize}
        colorsTexture={colorsTexture}
        selected={selection.selectedIds}
        highlighted={selection.highlightIds}
      />
      <SelectionTools geometry={geometry} />
    </group>
  );
}

export default React.memo(DatasetView);
