import intersection from "lodash/intersection";
export class Query {
  run(previous?: number[]): number[] {
    return previous || [];
  }

  merge(result: number[], previous?: number[]): number[] {
    return previous ? intersection(previous) : result;
  }
}
