import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useThree } from "@react-three/fiber";
import { CameraControls, OrthographicCamera } from "@react-three/drei";
import CameraControlsImpl from "camera-controls";
import { useToolStore } from "~stores/tool";

import { Cursor } from "~components/helpers/Cursor";

import { useShortcut } from "~hooks/useShortcut";

const TOP_KEY = "t";
const MOVE_KEY = " ";
const ROTATE_KEY = "Alt";

enum Action {
  None = CameraControlsImpl.ACTION.NONE,
  Move = CameraControlsImpl.ACTION.TRUCK,
  Rotate = CameraControlsImpl.ACTION.ROTATE,
  Zoom = CameraControlsImpl.ACTION.ZOOM,
}

function getAction(code?: number): Action {
  if (code === Action.Move) return Action.Move;
  if (code === Action.Rotate) return Action.Rotate;
  return Action.None;
}

function MainCameraControls() {
  const controls = useThree((s) =>
    s.controls instanceof CameraControlsImpl ? s.controls : null
  );
  const aspect = useThree((state) => state.size.width / state.size.height);

  const [isMovingMode, setMovingMode] = useState(false);
  const [isRotateMode, setRotatingMode] = useState(false);

  const [currentAction, setCurrentAction] = useState(Action.None);

  const { currentTool } = useToolStore();

  const lastPolar = useRef(Math.PI / 4);

  const handleTopKey = useCallback(() => {
    if (!controls) return;

    const isTop = controls.polarAngle < 0.001;
    console.log("isTop", isTop, controls.polarAngle);

    if (isTop) {
      controls.rotatePolarTo(lastPolar.current, true);
      return;
    }

    lastPolar.current = controls.polarAngle;
    controls.rotatePolarTo(0, true);
  }, [controls]);

  // Key handlers
  useShortcut(TOP_KEY, handleTopKey);
  useShortcut(
    MOVE_KEY,
    () => setMovingMode(true),
    () => setMovingMode(false)
  );

  useEffect(() => {
    if (currentTool === "pointer") {
      setMovingMode(true);
    } else {
      setMovingMode(false);
    }
  }, [currentTool]);

  useShortcut(
    ROTATE_KEY,
    () => setRotatingMode(true),
    () => setRotatingMode(false)
  );

  const handleActionChange = useCallback(() => {
    const action = getAction(controls?.currentAction);
    if (action !== currentAction) {
      setCurrentAction(action);
    }
  }, [controls, currentAction]);

  // Cursor state
  const cursor = useMemo(() => {
    if (controls?.enabled === false) return undefined;
    if (currentAction == Action.Move) return "move";
    if (currentAction === Action.Rotate) return "grabbing";
    if (isMovingMode) return "move";
  }, [controls, currentAction, isMovingMode]);

  const leftAction = useMemo(() => {
    if (isMovingMode) return CameraControlsImpl.ACTION.TRUCK;
    return CameraControlsImpl.ACTION.NONE;
  }, [isMovingMode]);

  const rightAction = useMemo(() => {
    if (isRotateMode) return CameraControlsImpl.ACTION.ROTATE;
    return CameraControlsImpl.ACTION.TRUCK;
  }, [isRotateMode]);

  return (
    <>
      <CameraControls
        onStart={handleActionChange}
        onEnd={handleActionChange}
        onChange={handleActionChange}
        makeDefault
        dollyToCursor
        dollyDragInverted
        dollySpeed={0.2}
        dragToOffset={true}
        minZoom={0.001}
        maxZoom={100}
        smoothTime={0.05}
        draggingSmoothTime={0.01}
        mouseButtons={{
          left: leftAction,
          wheel: CameraControlsImpl.ACTION.ZOOM,
          middle: CameraControlsImpl.ACTION.NONE,
          right: rightAction,
        }}
      />
      <OrthographicCamera
        manual
        makeDefault
        up={[0, 0, 1]}
        top={1}
        bottom={-1}
        left={-1 * aspect}
        right={1 * aspect}
        near={-1000}
        far={1000}
        zoom={0.1}
        position={[0, 0, 1]}
      />
      <Cursor value={cursor} priority={1} />
    </>
  );
}

export default MainCameraControls;
