// import { TextIndex } from "~dataset/TextIndex";
import { getMinMax } from "~dataset/utils";
import { Attribute } from "./Attribute";
import { TextAttributeObject } from "~dataset/types";

export class TextAttribute extends Attribute {
  type = "text";

  data: Uint8Array;
  offsets: Uint32Array;

  // index: TextIndex;

  static fromObject(obj: TextAttributeObject) {
    return new TextAttribute(obj.name, obj.data, obj.offsets);
  }

  static fromCategories(
    name: string,
    values: ArrayLike<number>,
    categories: string[]
  ) {
    const textValues: string[] = [];
    for (let i = 0; i < values.length; i++) {
      const range = getMinMax(values);
      const normalized = (values[i] - range[0]) / (range[1] - range[0]);
      const index = Math.round(normalized * (categories.length - 1));
      textValues.push(categories[index]);
    }

    return TextAttribute.fromTextValues(name, textValues);
  }

  static fromTextValues(name: string, textValues: string[]) {
    const encoder = new TextEncoder();
    const encodedValues = textValues.map((v) => encoder.encode(v));

    const totalLength = encodedValues.reduce((acc, v) => acc + v.length, 0);

    const data = new Uint8Array(totalLength);
    const offsets = new Uint32Array(encodedValues.length);

    let i = 0;
    let offset = 0;
    encodedValues.forEach((v) => {
      data.set(v, offset);
      offsets[i] = offset;
      offset += v.length;
      i++;
    });

    return new TextAttribute(name, data, offsets);
  }

  constructor(name: string, data: Uint8Array, offsets: Uint32Array) {
    super(name, offsets.length);
    this.data = data;
    this.offsets = offsets;
  }

  getValue(index: number): number {
    return index;
  }

  getRange(): [number, number] {
    return [0, this.offsets.length - 1];
  }

  getDisplayValue(index: number): string {
    const start = this.offsets[index];
    const end = this.offsets[index + 1];
    const binary = this.data.slice(start, end);
    return new TextDecoder().decode(binary);
  }

  toObject(): TextAttributeObject {
    return {
      type: "text",
      name: this.name,
      data: this.data,
      offsets: this.offsets,
    };
  }
}
