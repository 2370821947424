import React from "react";
import { Stack, StackProps, styled } from "@mui/system";

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingBottom: "16px",
  paddingTop: "10px",
  overflowY: "scroll",
  overflowX: "hidden",
  maskImage: `linear-gradient(to bottom, transparent 0%, black 16px, black calc(100% - 16px), transparent 100%)`,
}));

export function ScrollTextBox(props: StackProps) {
  return <StyledStack onWheel={(e) => e.stopPropagation()} {...props} />;
}
