import React from "react";
import { useDrop } from "react-use";
import Layer from "~components/ui/Layer";

export function DropLayer() {
  const { over } = useDrop();

  if (!over) {
    return null;
  }

  return <Layer style={{ background: "rgba(255,255,255,0.1)" }} />;
}
