import * as THREE from "three";
import { Attribute } from "~dataset/attributes/Attribute";
import { PositionAttributeObject } from "~dataset/types";
import { getMinMax } from "~dataset/utils";

export class PositionAttribute extends Attribute {
  type = "position";

  values: Float32Array;
  range: [number, number];

  static fromObject(obj: PositionAttributeObject): PositionAttribute {
    return new PositionAttribute(obj.values);
  }

  constructor(values: Float32Array) {
    super("position", values.length / 3);

    this.values = values;
    this.range = getMinMax(values);
  }

  getPosition(index: number, target = new THREE.Vector3()): THREE.Vector3 {
    return target.fromArray(this.values, index * 3);
  }

  getValue(index: number): number {
    return this.values[index];
  }

  getRange(): [number, number] {
    return this.range;
  }

  getDisplayValue(index: number): string {
    const { x, y, z } = this.getPosition(index);
    return `(${x.toFixed(2)}, ${y.toFixed(2)}, ${z.toFixed(2)})`;
  }

  toObject(): PositionAttributeObject {
    return {
      type: "position",
      name: this.name,
      values: this.values,
    };
  }
}
