import { Attribute } from "./Attribute";
import { getMinMax, normalizeArray } from "../utils";
import { EmbeddingAttributeObject } from "~dataset/types";

abstract class EmbeddingsProjector {
  abstract project(embeddings: EmbeddingAttribute): Float32Array;
}

// A projector that just picks the first dimensions
export class DefaultProjector extends EmbeddingsProjector {
  dimensions = 3;

  constructor(dimensions = 3) {
    super();
    this.dimensions = dimensions;
  }

  project(embeddings: EmbeddingAttribute) {
    const values = embeddings.values;
    const positions = new Float32Array(
      (values.length / embeddings.dimensions) * 3
    );

    const is3D = this.dimensions === 3 && embeddings.dimensions >= 3;

    let offset = 0;
    for (let i = 0; i < values.length; i += embeddings.dimensions) {
      positions[offset++] = values[i];
      positions[offset++] = values[i + 1];
      positions[offset++] = is3D ? values[i + 2] : 0;
    }

    normalizeArray(positions);

    return positions;
  }
}

const defaultProjector = new DefaultProjector();

export class EmbeddingAttribute extends Attribute {
  type = "embeddings";

  values: Float32Array;
  dimensions: number;

  range: [number, number] = [0, 1];

  static fromObject(obj: EmbeddingAttributeObject): EmbeddingAttribute {
    return new EmbeddingAttribute(obj.values, obj.dimensions);
  }

  constructor(values: Float32Array, dimensions: number) {
    super("embeddings", values.length / dimensions);

    this.values = values;
    this.dimensions = dimensions;

    this.range = getMinMax(values);
  }

  project(projector: EmbeddingsProjector = defaultProjector) {
    return projector.project(this);
  }

  getRange(): [number, number] {
    return this.range;
  }

  getValue(index: number): number {
    return this.values[index];
  }

  getDisplayValue(index: number): string {
    return this.getValue(index).toFixed(2);
  }

  toObject(): EmbeddingAttributeObject {
    return {
      type: "embedding",
      name: this.name,
      values: this.values,
      dimensions: this.dimensions,
    };
  }
}
