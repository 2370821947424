import React, { useCallback, useState } from "react";
import { Popover } from "@mui/material";
import { FloatingButton } from "./FloatingButton";

export function PanelButton({
  children,
  icon,
}: {
  children?: React.ReactNode;
  icon?: React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (anchorEl) {
        setAnchorEl(undefined);
        return;
      }
      setAnchorEl(e.currentTarget);
    },
    [anchorEl]
  );

  return (
    <>
      <FloatingButton selected={!!anchorEl} onClick={handleClick}>
        {icon}
      </FloatingButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={(theme) => ({
          "& .MuiPopover-paper": {
            width: 300,
            minHeight: 20,
            marginTop: theme.spacing(2),
          },
        })}
      >
        {children}
      </Popover>
    </>
  );
}
