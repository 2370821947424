import * as THREE from "three";
import { BoxTree } from "~math/boxTree/BoxTree";
import { useSettingsStore } from "~stores/settings";

export class DatasetGeometry extends THREE.BufferGeometry {
  positions: Float32Array;
  colors: Float32Array;
  zeros: Float32Array;
  ids: Uint32Array;

  count: number;
  boxTree: BoxTree;
  version = 0;

  colorAttributeName?: string;

  constructor(positions: Float32Array) {
    super();

    this.positions = this.setPositions(positions);
    this.boxTree = this.computeBoxTree(positions);
    this.count = positions.length / 3;

    this.zeros = new Float32Array(this.count);
    this.colors = new Float32Array(this.count);

    // Set ids
    this.ids = new Uint32Array(this.count);
    for (let i = 0; i < this.count; i++) {
      this.ids[i] = i;
    }

    // Geometry attributes
    this.setAttribute("zero", new THREE.BufferAttribute(this.zeros, 1));
    this.setAttribute("color", new THREE.BufferAttribute(this.colors, 1));
    this.setAttribute("id", new THREE.BufferAttribute(this.ids, 1));
  }

  computeBoxTree(positions: Float32Array) {
    this.boxTree = BoxTree.fromPositions(positions, {
      maxDepth: 10,
      maxElements: 1000,
      octree: true,
    });

    return this.boxTree;
  }

  setPositions(values: Float32Array) {
    this.setAttribute("position", new THREE.BufferAttribute(values, 3));
    this.positions = values;
    return this.positions;
  }

  resetIndex() {
    this.index = new THREE.BufferAttribute(this.ids, 1);
  }

  setColorAttribute(
    name?: string,
    values?: Float32Array | Int32Array,
    range?: [number, number]
  ) {
    const { colorPaletteLength } = useSettingsStore.getState();
    
    let paletteLength = 10;
    if (colorPaletteLength) paletteLength = colorPaletteLength;

    if (!name) {
      this.colors.fill(255);
      this.getAttribute("color").needsUpdate = true;
      return;
    }

    if (!values || !range) {
      throw new Error("Values and range must be set");
    }

    const [min, max] = range;
    const color = this.getAttribute("color") as THREE.BufferAttribute;
    for (let i = 0; i < values.length; i++) {
      this.colors[i] = Math.floor((values[i] - min) / (max - min) * paletteLength) / paletteLength + (0.5 / paletteLength);
    }
    color.needsUpdate = true;
  }
}
