export abstract class Attribute {
  type = "attribute";

  name: string;
  count: number;

  values?: Float32Array | Int32Array;

  static fromObject(obj: any): Attribute {
    throw new Error("Not implemented");
  }

  constructor(name: string, count: number) {
    this.name = name;
    this.count = count;
  }

  abstract getValue(index: number): number;
  abstract getRange(): [number, number];
  abstract getDisplayValue(index: number): string;

  abstract toObject(): any;
}
