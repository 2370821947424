export interface ColorPalette {
  name: string;
  texture: string;
}

export default [
  {
    name: "Default",
    texture: require("~/assets/palettes/default.png") as string,
  },
  {
    name: "Pastel",
    texture: require("~/assets/palettes/pastel.png") as string,
  },
  {
    name: "Range",
    texture: require("~/assets/palettes/range.png") as string,
  },
  {
    name: "Range Flipped",
    texture: require("~/assets/palettes/range-flipped.png") as string,
  },
] as ColorPalette[];
