import React from "react";

import DatasetLoader from "./DatasetLoader";
import CSVLoader from "./CSVLoader";
import BS5SceneLoader from "./BS5SceneLoader";
import { Dataset } from "~dataset/Dataset";

export function Loaders({ onLoad }: { onLoad: (dataset: Dataset) => void }) {
  return (
    <>
      <DatasetLoader onLoad={onLoad} />
      <CSVLoader onLoad={onLoad} />
      <BS5SceneLoader onLoad={onLoad} />
    </>
  );
}
