import React from "react";

import { Button, ButtonProps, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(11),
  minWidth: theme.spacing(11),
  height: theme.spacing(11),
}));

export function FloatingButton({
  selected,
  ...props
}: ButtonProps & { selected?: boolean }) {
  return (
    <StyledButton
      variant="outlined"
      color={selected ? "secondary" : "primary"}
      {...props}
    />
  );
}
