import React, { useMemo, useState } from "react";
import { LoaderSnackbar } from "~components/ui/LoaderSnackbar";
import { useDataStore } from "~stores/data";

export function DatasetLoaderProgress() {
  const dataset = useDataStore((s) => s.dataset);
  const loading = useDataStore((s) => s.loading);

  const progress = useMemo(() => {
    if (loading) {
      return 0;
    }
    return 1;
  }, [loading]);

  if (!dataset) {
    return null;
  }
  
  if (progress >= 1) {
    return null;
  }

  return <LoaderSnackbar title="Loading dataset..." />;
}
