import { useCallback, useEffect } from "react";

// Prevents input elements from propagating keydown events.
export function InputKeydownHandler() {
  const handler = useCallback((e: KeyboardEvent) => {
    if (e.target instanceof HTMLInputElement) {
      e.stopPropagation();
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handler);
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, []);

  return null;
}
