import React, { useEffect, useState } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "~/theme";

import { SelectionPanel } from "./panels/SelectionPanel";
import { Stack } from "@mui/material";
import Layer from "~/components/ui/Layer";
import { DropLayer } from "./helpers/DropLayer";
import { Loaders } from "./loaders/Loaders";
import { InputKeydownHandler } from "./handlers/InputKeydownHandler";
import { Toolbar } from "./Toolbar";
import { DatasetLoaderProgress } from "./helpers/DatasetLoaderProgress";
import { ColorPanel } from "./panels/ColorPanel";
import { useSearchParam } from "react-use";

// Import this from other apps
import {
  EmbeddingsVisualizer,
  loadDataset,
  loadDatasetUrl,
} from "./EmbeddingsVisualizer";

export const App = () => {

  const getDatasetUrlFromURL = () => {
    const queryString = window.location.search.substring(1); // Remove the leading '?'
    // Can't use regular search params since signed s3 links have '&' or '=' or other special characters
    const [name, value] = queryString.split("datasetUrl=", 2);
    return value;
  };

  const datasetUrl = getDatasetUrlFromURL();
  useEffect(() => {
    if (datasetUrl) {
      loadDatasetUrl(datasetUrl);
    }
  }, [datasetUrl]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <InputKeydownHandler />
      <Layer sx={{ background: "#111" }}>
        <EmbeddingsVisualizer />
      </Layer>
      <Layer>
        <Stack
          height="100%"
          overflow="hidden"
          justifyContent="space-between"
          alignItems="baseline"
          spacing={2}
          padding={4}
        >
          <Toolbar />
          <Stack
            width="100%"
            direction="row"
            spacing={2}
            alignItems="end"
            justifyContent="space-between"
          >
            <SelectionPanel />
            <ColorPanel />
          </Stack>
        </Stack>
      </Layer>
      <DropLayer />
      <Loaders onLoad={loadDataset} />
      <DatasetLoaderProgress />
    </ThemeProvider>
  );
};

window.addEventListener(
  "wheel",
  (e) => {
    e.preventDefault();
  },
  {
    passive: false,
  }
);
