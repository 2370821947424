import { Query } from "~dataset/queries/Query";
import BoxTree from "../../math/boxTree/BoxTree";

export interface BoxTreeQueryParams {
  boxTree: BoxTree;
  points: Float32Array;
}
export class BoxTreeQuery extends Query {
  boxTree: BoxTree;
  points: Float32Array;

  constructor(params: BoxTreeQueryParams) {
    super();
    this.boxTree = params.boxTree;
    this.points = params.points;
  }

  run(previous?: number[]): number[] {
    throw new Error("Method not implemented.");
  }
}
