import { useEffect } from "react";
import { create } from "zustand";
import { nanoid } from "nanoid";
import { useThree } from "@react-three/fiber";

interface CursorClaim {
  id: string;
  value: string;
  priority: number;
}

interface CursorStore {
  claims: CursorClaim[];
  claim: (id: string, value: string, priority: number) => CursorClaim;
  release: (id: string) => void;
  update: () => void;
  currentCursor: string;
}

export const useCursorStore = create<CursorStore>((set, get) => ({
  claims: [],
  currentCursor: "auto",
  claim: (id: string, value: string, priority: number) => {
    const claim: CursorClaim = { id, value, priority };
    set((state) => ({ claims: [...state.claims, claim] }));
    get().update();
    return claim;
  },
  release: (id: string) => {
    set((state) => ({
      claims: state.claims.filter((claim) => claim.id !== id),
    }));
    get().update();
  },
  update: () => {
    const sorted = get().claims.sort((a, b) => b.priority - a.priority);
    const cursor = sorted[0]?.value || "auto";
    if (get().currentCursor !== cursor) {
      set({ currentCursor: cursor });
    }
  },
}));

export function Cursor({
  value,
  priority = 0,
}: {
  value?: string;
  priority?: number;
}) {
  const { gl } = useThree();
  const { claim, release, currentCursor } = useCursorStore();

  useEffect(() => {
    if (value) {
      const id = nanoid();
      claim(id, value, priority);
      return () => {
        release(id);
      };
    }
  }, [value, claim, release]);

  useEffect(() => {
    if (currentCursor !== gl.domElement.style.cursor) {
      gl.domElement.style.cursor = currentCursor;
    }
  }, [currentCursor]);

  return null;
}

