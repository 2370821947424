import React, { useMemo } from "react";

import SettingsIcon from "@mui/icons-material/Settings";
import { PanelButton } from "~components/ui/PanelButton";
import { Stack, Typography } from "@mui/material";
import { useSettingsStore } from "~stores/settings";
import ColorPalettes from "~config/palettes";

import { SliderField } from "~components/ui/fields/SliderField";
import { useDataStore } from "~stores/data";
import { SelectField } from "~components/ui/fields/SelectField";

function PaletteControl() {
  const settings = useSettingsStore();

  return (
    <SelectField
      label="Palette"
      options={ColorPalettes}
      renderValue={(value) => value.name}
      value={settings.colorPalette}
      onChange={settings.setColorPalette}
    />
  );
}

function ColorAttributeControl() {
  const { colorAttribute, setColorAttribute, dataset } = useDataStore();

  const attributeNames = useMemo(() => {
    const datasetAttributes = dataset?.attributes
      .filter((a) => a.type === "number" || a.type === "category")
      .map((a) => a.name);
    return ["None", ...(datasetAttributes ?? [])];
  }, [dataset]);

  if (!dataset) {
    return null;
  }

  return (
    <SelectField
      label="Color by"
      options={attributeNames}
      value={colorAttribute ?? "None"}
      onChange={setColorAttribute}
    />
  );
}

function ColorLegendControl() {
  const settings = useSettingsStore();

  return (
    <SelectField
      label="Legend"
      options={[true, false]}
      renderValue={(value) => (value ? "Show" : "Hide")}
      value={settings.showColorLegend}
      onChange={settings.setShowColorLegend}
    />
  );
}

function PointSizeControl() {
  const settings = useSettingsStore();

  return (
    <SliderField
      label="Point size"
      minValue={2}
      maxValue={30}
      value={settings.pointSize}
      onChange={settings.setPointSize}
    />
  );
}

export function SettingsPanel() {
  return (
    <PanelButton icon={<SettingsIcon />}>
      <Stack padding={3} spacing={1.5}>
        <Typography align="center" padding={1}>
          Settings
        </Typography>
        <PointSizeControl />
        <PaletteControl />
        <ColorAttributeControl />
        <ColorLegendControl />
      </Stack>
    </PanelButton>
  );
}
