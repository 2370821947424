import { useEffect } from "react";
import { create } from "zustand";

export type ToolType = "pointer" | "lasso" | "brush" | string;

export interface ToolStore {
  tools: ToolType[];
  currentTool: string;
  previousTool: string;

  setTool: (tool: ToolType) => void;
  setPreviousTool: () => void;

  registerTool: (tool: ToolType) => void;
  unregisterTool: (tool: ToolType) => void;
}

export const useToolStore = create<ToolStore>((set, get) => ({
  tools: [],

  currentTool: "pointer",
  previousTool: "pointer",

  setTool: (currentTool) => {
    set({ currentTool, previousTool: get().currentTool });
  },

  setPreviousTool: () => {
    set({ currentTool: get().previousTool });
  },

  registerTool: (tool) => {
    set((state) => ({ tools: [...state.tools, tool] }));
  },

  unregisterTool: (tool) => {
    set((state) => ({ tools: state.tools.filter((t) => t !== tool) }));
  },
}));

export const useRegisterTool = (tool: ToolType) => {
  useEffect(() => {
    useToolStore.getState().registerTool(tool);
    return () => useToolStore.getState().unregisterTool(tool);
  }, [tool]);
};
