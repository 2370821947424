import CameraControlsImpl from "camera-controls";
import { useThree } from "@react-three/fiber";
import { useCallback, useState } from "react";
import { useEvent } from "react-use";

interface CameraControlsState {
  controls?: CameraControlsImpl;
  active: boolean;
}

export const useCameraControls: () => CameraControlsState = () => {
  const [active, setActive] = useState(false);

  const controls = useThree((s) => {
    if (s.controls instanceof CameraControlsImpl) {
      return s.controls;
    }
  });

  const handleStart = useCallback(() => {
    if (controls && controls.currentAction > 0) {
      // console.log('setActive true');
      setActive(true);
    }
  }, [controls]);

  const handleEnd = useCallback(() => {
    // console.log('setActive false');
    setActive(false);
  }, [controls]);

  useEvent("controlstart", handleStart, controls);
  useEvent("controlend", handleEnd, controls);

  return {
    controls,
    active,
  };
};
