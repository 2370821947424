import { useFrame } from "@react-three/fiber";
import { useMemo } from "react";
import * as THREE from "three";
import { useClipToCanvasTransform } from "~hooks/useClipToCanvasTransform";

export function useSceneToCanvasTransform() {
  const transform = useMemo(() => new THREE.Matrix4(), []);
  const clipToCanvas = useClipToCanvasTransform();

  useFrame(({ camera, scene }) => {
    transform
      .copy(scene.matrixWorld)
      .premultiply(camera.matrixWorldInverse)
      .premultiply(camera.projectionMatrix)
      .premultiply(clipToCanvas);
  });

  return transform;
}
