// import { TextIndex } from "~dataset/TextIndex";
import { ImageAttributeObject } from "~dataset/types";
import { Attribute } from "./Attribute";

export class ImageAttribute extends Attribute {
  type = "image";

  data: Uint8Array;
  offsets: Uint32Array;

  static fromObject(obj: ImageAttributeObject) {
    return new ImageAttribute(obj.name, obj.data, obj.offsets);
  }

  static async fromImageUrls(
    name: string,
    imageUrls: string[]
  ): Promise<ImageAttribute> {
    const images = await Promise.all(
      imageUrls.map(async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        return new Uint8Array(arrayBuffer);
      })
    );
    return ImageAttribute.fromImages(name, images);
  }

  static fromImages(name: string, images: Uint8Array[]): ImageAttribute {
    const totalLength = images.reduce((acc, v) => acc + v.length, 0);

    const dataBuffer = new SharedArrayBuffer(totalLength);
    const offsetsBuffer = new SharedArrayBuffer(images.length * 4);

    const data = new Uint8Array(dataBuffer);
    const offsets = new Uint32Array(offsetsBuffer);

    let i = 0;
    let offset = 0;
    images.forEach((v) => {
      data.set(v, offset);
      offsets[i] = offset;
      offset += v.length;
      i++;
    });

    return new ImageAttribute(name, data, offsets);
  }

  constructor(name: string, data: Uint8Array, offsets: Uint32Array) {
    super(name, offsets.length);
    this.data = data;
    this.offsets = offsets;
  }

  getValue(index: number): number {
    return index;
  }

  getRange(): [number, number] {
    return [0, this.offsets.length - 1];
  }

  getDisplayValue(index: number): string {
    const start = this.offsets[index];
    const end = this.offsets[index + 1];
    const binary = this.data.slice(start, end);
    const blob = new Blob([binary], { type: "image/jpeg" });
    return URL.createObjectURL(blob);
  }

  toObject(): ImageAttributeObject {
    return {
      type: "image",
      name: this.name,
      data: this.data,
      offsets: this.offsets,
    };
  }
}
